import { API } from "aws-amplify";

const UserGet = async () => {
  try {
    let reqData = {
      response: true,
    };

    const { data } = await API.get("user", "", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const UserGetAdmins = async () => {
  try {
    let reqData = {
      response: true,
    };

    const { data } = await API.get("user/admins", "", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const UserGetOrganizationUsers = async ({ organizationId }) => {
  try {
    let reqData = {
      response: true,
      queryStringParameters: { organizationId },
    };

    const { data } = await API.get("user/organization", "", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const UserCreate = async ({ createUserData }) => {
  try {
    let reqData = {
      response: true,
      body: createUserData,
    };
    const { data } = await API.post("user", "", reqData);
    return data;
  } catch (err) {
    switch (err?.response?.data?.error) {
      case "UsernameExistsException":
        throw "User already exists under that email address.";
      default:
        throw err?.response?.data?.error;
    }
  }
};

const UserUpdate = async ({ editUserData }) => {
  try {
    let reqData = {
      response: true,
      body: editUserData,
    };
    const { data } = await API.put("user", "", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const UserDelete = async ({ deleteUserId, reactivate }) => {
  try {
    let reqData = {
      response: true,
      body: { deleteUserId },
    };

    if (reactivate) {
      reqData.body.reactivate = true;
    }

    const { data } = await API.del("user", "", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const UserAcceptTCPP = async () => {
  try {
    let reqData = {
      response: true,
      body: {},
    };
    const { data } = await API.post("user", "accept-tc-pp", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const Methods = {
  UserGet,
  UserDelete,
  UserGetAdmins,
  UserCreate,
  UserUpdate,
  UserGetOrganizationUsers,
  UserAcceptTCPP,
};

export default Methods;
