import { useState } from "react";
import ModalComponent from "../../components/Modal";
import styled from "styled-components";
import ConfirmForm from "../../components/Forms/confirmForm";
import colors from "../../theme/colors";
import API from "../../api";
import { useSelector, useDispatch } from "react-redux";
import {
  remove as removeNotification,
  add as addNotification,
} from "../../redux-store/notificationsSlice";

import { updateDeviceConnectivityTestModal } from "../../redux-store/uxSlice";

export default function DeviceConnectivityTestModal() {
  const dispatch = useDispatch();

  const { deviceConnectivityTestModal } = useSelector((state) => state.ux);

  const [savingConnectivityTest, setSavingConnectivityTest] = useState(false);
  const [savingConnectivityTestError, setSavingConnectivityTestError] =
    useState("");

  const ModalHeader = styled.span`
    color: ${colors.gray};
    margin-left: 5px;
  `;

  const dataLayer = {
    StartConnectivityTest: async () => {
      try {
        const res = await API.Device.DeviceConnectivityTestStart({
          deviceId: deviceConnectivityTestModal?.device.id,
        });

        //Create Success Notification
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "success",
            title: "Success",
            message: "Device Connectivity Test Started",
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      } catch (err) {
        console.log(err);
        //Create Error Notification
        const id = new Date().getTime();
        let message = err;
        switch (err) {
          case "device-connectivity-test-active-for-device-id":
            message =
              "Device has Connectivity Test Active. Please wait until it's completed before starting another one.";
            break;
          default:
        }
        dispatch(
          addNotification({
            id,
            severity: "error",
            title: "Error",
            message,
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      }
    },
  };

  const handlers = {
    closeConnectivityTestModalClick: () => {
      setSavingConnectivityTest(false);
      dispatch(
        updateDeviceConnectivityTestModal({
          device: {},
          show: false,
        })
      );
    },
    startConnectivityTestClick: async () => {
      setSavingConnectivityTest(true);
      //Send API Request
      await dataLayer.StartConnectivityTest();
      setSavingConnectivityTest(false);
      handlers.closeConnectivityTestModalClick();
    },
  };

  return (
    <ModalComponent
      open={deviceConnectivityTestModal.show}
      handleClose={handlers.closeConnectivityTestModalClick}
      headerText={
        <>
          Start Connectivity Test
          <ModalHeader>
            / {deviceConnectivityTestModal?.device?.name}
          </ModalHeader>
        </>
      }
    >
      <ConfirmForm
        handleCancel={handlers.closeConnectivityTestModalClick}
        handleSave={handlers.startConnectivityTestClick}
        cancelButtonText="Cancel"
        saveButtonText="Start"
        savingButtonText="Starting"
        error={savingConnectivityTestError}
        loading={savingConnectivityTest}
      >
        <div>
          The device will now undergo a connectivity test. This test will take
          up to 4 hours and will run in the background. Check the status of the
          test in the Devices table or on this Device's dashboard.
        </div>
        <b>Before starting Connectivity Test</b>
        <div>1. Unplug device from charger</div>
        <div>2. Verify device is NOT in long mode</div>
      </ConfirmForm>
    </ModalComponent>
  );
}
