// Boiler Plate Imports
import styled from "styled-components";
import colors from "../../theme/colors";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  remove as removeNotification,
  add as addNotification,
} from "../../redux-store/notificationsSlice";
import { update as setBreadCrumbs } from "../../redux-store/breadCrumbsSlice";
import { update as updateUserProfile } from "../../redux-store/userSlice";
import API from "../../api";

// Conatiner Component Imports
import { Button, Card } from "@mui/material";
import Table from "../../components/Table";
import BreadCrumbs from "../../components/Navigation/breadcrumbs";
import ModalComponent from "../../components/Modal";
import TableHeader from "../../components/Page/tableHeader";
import TableSubHeader from "../../components/Page/tableSubHeader";

// Page Component Imports
import OrganizationLocationForm from "../../components/Forms/organizationLocationForm";
import { FormatPhoneNumber } from "../../components/PhoneNumberInput";
import { updateReloadOrganization } from "../../redux-store/uxSlice";
import { FormatAddressShort } from "../../components/Forms/shared/address";
import { useParams } from "react-router-dom";

const ActiveAlarmsTagNone = styled.div`
  background: ${colors.grayLight};
  color: ${colors.grayDark};
  border: 1px solid ${colors.gray};
  border-radius: 15px;
  text-align: center;
  padding: 2px;
  width: 60px;
`;

const ActiveAlarmsTagSome = styled.div`
  background: ${colors.redLightError};
  color: ${colors.redError};
  border: 1px solid ${colors.redError};
  border-radius: 15px;
  text-align: center;
  padding: 2px;
  width: 60px;
  font-weight: bold;
`;

const OrganizationLocationsContainer = () => {
  const userProfile = useSelector((state) => state.user);
  const organization = useSelector((state) => state.organization);

  const [savingLocation, setSavingLocation] = useState(false);
  const [savingLocationError, setSavingLocationError] = useState(false);
  const [locationTableRows, setLocationTableRows] = useState([]);
  const [loadingLocations, setLoadingLocations] = useState(true);

  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();

  let { organizationId } = useParams();

  const locationTableColumns = [
    {
      title: "Location Name",
      propertyName: "name",
      sortProperty: "name",
      sortPropertyType: "string",
      navigate: (row) => {
        return `/${organizationId}/locations/${row.id}`;
      },
    },
    {
      title: "Active Alarms",
      propertyName: "activeAlarmsCount",
      sortProperty: "activeAlarmsCount",
      sortPropertyType: "number",
      sortDefault: "desc",
      render: (row) => {
        if (row.activeAlarmsCount === 0) {
          return <ActiveAlarmsTagNone>none</ActiveAlarmsTagNone>;
        } else {
          return (
            <ActiveAlarmsTagSome>{row.activeAlarmsCount}</ActiveAlarmsTagSome>
          );
        }
      },
    },
    {
      title: "Tanks",
      propertyName: "tanksCount",
      sortProperty: "tanksCount",
      sortPropertyType: "number",
    },
    {
      title: "Address",
      propertyName: "address",
      sortProperty: "address",
      sortPropertyType: "number",
    },
    {
      title: "Phone",
      propertyName: "phoneString",
      sortProperty: "phone",
      sortPropertyType: "string",
    },
    {
      title: "Date Created",
      propertyName: "dateCreateString",
      sortProperty: "created",
      sortPropertyType: "number",
    },
  ];

  useEffect(() => {
    if (organizationId) {
      dataLayer.GetOrganizationLocations();
    }
  }, [organizationId]);

  useEffect(() => {
    //Set Bread Crumbs
    dispatch(
      setBreadCrumbs([
        {
          title: organization.name,
          link: "/organization",
        },
        {
          title: "Locations",
          link: "/locations",
        },
      ])
    );
  }, [organization]);

  const dataLayer = {
    GetOrganizationLocations: async () => {
      setLoadingLocations(true);
      const locations = await API.Location.LocationsGet({ organizationId });
      const locationRows = locations.map((location) => {
        return {
          ...location,
          status: (() => {
            if (location.deleted) {
              return "Deactivated";
            } else {
              return "Active";
            }
          })(),
          dateCreateString: new Date(location.created).toLocaleString(),
          phoneString: FormatPhoneNumber(location.phone),
          address: FormatAddressShort(location),
          activeAlarms: 0,
        };
      });
      locationRows.sort((a, b) => {
        return b.created - a.created;
      });
      setLocationTableRows(locationRows);
      setLoadingLocations(false);
    },
  };

  const handlers = {
    addLocationClick: () => {
      setSavingLocationError("");
      setOpenModal(true);
    },
    closeLocationModal: () => {
      if (!savingLocation) {
        setOpenModal(false);
      }
    },
    saveLocation: async (data) => {
      setSavingLocationError("");
      setSavingLocation(true);
      try {
        await API.Location.LocationCreate({
          createLocationData: { ...data, organizationId },
        });
        //Reload Table Data
        dataLayer.GetOrganizationLocations();
        dispatch(updateReloadOrganization(true));
        //Create Success Notification
        setOpenModal(false);
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "success",
            title: "Success",
            message: "Location created successfully",
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      } catch (err) {
        setSavingLocationError(err);
        console.log(err);
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "error",
            title: "Error",
            message: err,
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      }
      setSavingLocation(false);
    },
  };

  return (
    <>
      <BreadCrumbs />
      <Card>
        <TableHeader>
          <div>
            <h2>Locations</h2>
            <TableSubHeader>
              Below is a list of all Locations within this Organization
            </TableSubHeader>
          </div>

          <Button
            onClick={handlers.addLocationClick}
            variant="contained"
            color="primary"
            size="large"
          >
            ADD LOCATION
          </Button>
        </TableHeader>
        <Table
          columns={locationTableColumns}
          rows={locationTableRows}
          loading={loadingLocations}
          placeholder="There are no location created yet"
        />
      </Card>
      <ModalComponent headerText="Add Location" open={openModal}>
        <OrganizationLocationForm
          handleSave={handlers.saveLocation}
          handleCancel={handlers.closeLocationModal}
          loading={savingLocation}
          error={savingLocationError}
        />
      </ModalComponent>
    </>
  );
};

export default OrganizationLocationsContainer;
