import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import colors from "../../theme/colors";

const SparklineComponent = ({ seriesData = [] }) => {
  const options = {
    chart: {
      backgroundColor: colors.grayLightest,
      borderWidth: 0,
      type: "area",
      margin: [10, 0, 0, 0],
      width: 200,
      height: 30,
      style: {
        overflow: "visible",
      },
      skipClone: true,
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      labels: {
        enabled: false,
      },
      title: {
        enabled: false,
      },
      startOnTick: false,
      endOnTick: false,
      tickPositions: [],
    },
    yAxis: {
      endOnTick: false,
      startOnTick: false,
      labels: {
        enabled: false,
      },
      title: {
        enabled: false,
      },
      tickPositions: [0],
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      hideDelay: 0,
      outside: true,
      shared: true,
      headerFormat: "<b>{point.y} kg</b><br />",
      pointFormatter: function () {
        return new Date(this.x * 1000).toLocaleString();
      },
    },
    plotOptions: {
      series: {
        animation: false,
        lineWidth: 1,
        shadow: false,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        marker: {
          radius: 1,
          states: {
            hover: {
              radius: 2,
            },
          },
        },
        fillOpacity: 0.25,
      },
    },
    series: [
      {
        name: "Weight KG",
        data: seriesData,
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default SparklineComponent;
