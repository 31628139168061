import {
  TextField,
  Button,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  InputAdornment,
  Switch,
} from "@mui/material";
import FormFieldErrorClass from "./models/formFieldError";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ErrorIcon from "@mui/icons-material/Error";
import { useState, useEffect } from "react";
import {
  FormContainer,
  FormSubmitError,
  ActionButtonsContainer,
  InputFieldsWrapper,
  HeaderNote,
} from "./shared";
import styled from "styled-components";
import colors from "../../theme/colors";
import { useDispatch, useSelector } from "react-redux";
import {
  add as addNotification,
  remove as removeNotification,
} from "../../redux-store/notificationsSlice";
import isNumeric from "validator/lib/isNumeric";
import { FixedTwoDecimalPlaces } from "../../utils/numbers";
import { formatDateTimeStamp } from "../../utils/date";
import { TrashIcon } from "../../theme/icons";

const SubSectionHeader = styled.div`
  font-size: 16px;
  border-bottom: 1px solid ${colors.gray};
  margin: 20px 0;
  font-weight: 500;
  &.no-bottom-margin {
    margin: 20px 0 0 0;
  }
`;

const ActionButtonsContainerWithSpace = styled(ActionButtonsContainer)`
  margin: 35px 0 60px;
`;

const InlineToolTip = styled.div`
  font-size: 12px;
`;

const DetailSubHeader = styled.div`
  font-size: 12px;
  margin-bottom: 12px;
`;

const CustomToggleFieldContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DeviceRowWrapper = styled.div`
  display: flex;
  align-item: center;
  justify-content: center;
  gap: 10px;
`;

const DeviceRowNumber = styled.div`
  display: flex;
  align-items: center;
  width: 25px;
  font-size: 12px;
`;

class DeviceClass {
  constructor(particleDeviceId) {
    this.particleDeviceId = particleDeviceId;
    this.error = new FormFieldErrorClass();
  }
}

const AdminDevicesForm = ({
  handleCancel = () => {},
  handleSave = () => {},
  saving = false,
  error = "",
  firmwareList = [],
}) => {
  const dispatch = useDispatch();
  const { isAdminUser } = useSelector((state) => state.ux);

  //UX State
  const [save, setSave] = useState(false);

  //Form Fields
  const [devices, setDevices] = useState([]);
  const [desiredFirmwareVersion, setDesiredFirmwareVersion] = useState(
    firmwareList[0].version
  );
  const boardTypes = ["reve", "revc-plus"];
  const [boardType, setBoardType] = useState(boardTypes[0]);

  //Error State
  const [desiredFirmwareVersionError, setDesiredFirmwareVersionError] =
    useState(new FormFieldErrorClass());
  const [boardTypeError, setBoardTypeError] = useState(
    new FormFieldErrorClass()
  );

  const formFieldErrors = [desiredFirmwareVersionError, boardTypeError];

  useEffect(() => {
    if (save) {
      setSave(false);
      if (
        formFieldErrors.filter((e) => e.hasError).length ||
        devices.filter((device) => device.error.hasError).length
      ) {
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "error",
            title: "Form Error",
            message: "Please check all form inputs for errors",
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 4000);
        return;
      }

      //send data
      handleSave({
        devices,
        desiredFirmwareVersion,
        boardType,
      });
    }
  }, [save]);

  useEffect(() => {
    if (devices.length === 0) {
      setDevices([new DeviceClass("")]);
    }
    validate.particleDevices();
  }, [devices]);

  const validate = {
    particleDevices: () => {
      devices.forEach((device, i) => {
        validate.particleDevice(device, i);
      });
    },
    firmwareVersion: (value) => {
      setDesiredFirmwareVersionError({
        hasError: false,
        message: "",
      });
      if (!value) {
        setDesiredFirmwareVersionError({
          hasError: true,
          message: "Frimware Version Required",
        });
      }
    },
    boardType: (value) => {
      setBoardTypeError({
        hasError: false,
        message: "",
      });
      if (!value) {
        setBoardTypeError({
          hasError: true,
          message: "Board Type Required",
        });
      }
    },
    particleDevice: (device, index) => {
      const _devices = devices.slice();
      const _device = _devices[index];
      _device.error = {
        hasError: false,
        message: "",
      };
      if (!_device.particleDeviceId) {
        _device.error = {
          hasError: true,
          message: "Particle Device Id Required",
        };
      } else if (_device.particleDeviceId.length !== 24) {
        _device.error = {
          hasError: true,
          message: "Particle Device Id Required to be 24 Characters",
        };
      }
      setDevices(_devices);
    },
  };

  const handlers = {
    particleDeviceIdChange: ({ target, index }) => {
      let { value } = target;
      value = value.replace(" ", "");
      const _devices = devices.slice();
      _devices[index].particleDeviceId = value;
      setDevices(_devices);
    },
    firmwareVersionChange: ({ target }) => {
      const { value } = target;
      validate.firmwareVersion(value);
      setDesiredFirmwareVersion(value);
    },
    boardTypeChange: ({ target }) => {
      const { value } = target;
      validate.boardType(value);
      setBoardType(value);
    },
    particleDeviceIdDeleteClick: ({ currentTarget }) => {
      const { value } = currentTarget;
      const _devices = devices.slice();
      _devices.splice(value, 1);
      setDevices(_devices);
      console.log(value);
    },
    addDeviceRowClick: () => {
      const _devices = devices.slice();
      _devices.push(new DeviceClass(""));
      setDevices(_devices);
    },
    save: () => {
      //validate all
      validate.particleDevices();
      validate.boardType(boardType);
      validate.firmwareVersion(desiredFirmwareVersion);
      setSave(true);
    },
    cancel: () => {
      handleCancel();
    },
  };

  return (
    <>
      <FormContainer>
        <InputFieldsWrapper>
          <div style={{ borderBottom: `1px solid ${colors.grayLight}` }}>
            <div>Firmware Version</div>
            <DetailSubHeader>
              Devices in the list below will be flashed with selected frimware
              version next time they appear online.
            </DetailSubHeader>
          </div>
          <TextField
            id="firmware_list"
            select
            variant="outlined"
            label="Desired Firmware Version"
            value={desiredFirmwareVersion}
            helperText={desiredFirmwareVersionError.message}
            error={desiredFirmwareVersionError.hasError}
            SelectProps={{
              native: true,
            }}
            onChange={handlers.firmwareVersionChange}
          >
            {firmwareList.map((item) => {
              return (
                <option key={item.version} value={item.version}>
                  v{item.version} - {item.title} - {" "}
                  {formatDateTimeStamp(new Date(item.uploaded_on).getTime())}
                </option>
              );
            })}
          </TextField>

          <div style={{ borderBottom: `1px solid ${colors.grayLight}` }}>
            <div>Board Type</div>
            <DetailSubHeader>
              Select the board type of all new Devices. Groups in Particle will
              be attched to new devices based on this selection. Note: do not
              add multiple particle device ids below with different board types.
            </DetailSubHeader>
          </div>

          <TextField
            id="board_type"
            select
            variant="outlined"
            label="Board Type"
            value={boardType}
            helperText={boardTypeError.message}
            error={boardTypeError.hasError}
            SelectProps={{
              native: true,
            }}
            onChange={handlers.boardTypeChange}
          >
            {boardTypes.map((item) => {
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })}
          </TextField>

          <div style={{ borderBottom: `1px solid ${colors.grayLight}` }}>
            <div>Particle Device Ids</div>
            <DetailSubHeader>
              Enter Particle Device Ids below. To add mulitiple devices at once,
              click “Add Another” and input multiple Ids
            </DetailSubHeader>
          </div>

          {devices.map((device, i) => {
            return (
              <DeviceRowWrapper key={`device-key-${i}`}>
                <DeviceRowNumber>{i + 1}.</DeviceRowNumber>
                <TextField
                  sx={{ width: "100%" }}
                  id={`device-input-${device.particleDeviceId}`}
                  label="Particle Device Id"
                  variant="outlined"
                  value={device.particleDeviceId}
                  onChange={(e) => {
                    handlers.particleDeviceIdChange({
                      target: e.target,
                      index: i,
                    });
                  }}
                  helperText={device.error.message}
                  error={device.error.hasError}
                />
                <Button
                  value={i}
                  onClick={handlers.particleDeviceIdDeleteClick}
                >
                  <TrashIcon
                    sx={{ color: colors.redError, fontSize: "30px" }}
                  />
                </Button>
              </DeviceRowWrapper>
            );
          })}

          <Button onClick={handlers.addDeviceRowClick} variant="outlined">
            Add Another Device
          </Button>

          {error && (
            <FormSubmitError>
              <ErrorIcon fontSize="small" />
              {error}
            </FormSubmitError>
          )}
          <ActionButtonsContainerWithSpace>
            <Button onClick={handlers.cancel}>Cancel</Button>
            {saving ? (
              <Button disabled variant="contained">
                Saving&#8230;
              </Button>
            ) : (
              <Button onClick={handlers.save} variant="contained">
                Save
              </Button>
            )}
          </ActionButtonsContainerWithSpace>
        </InputFieldsWrapper>
      </FormContainer>
    </>
  );
};

export default AdminDevicesForm;
