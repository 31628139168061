import { API } from "aws-amplify";

const DeviceGet = async ({ deviceId }) => {
  try {
    let reqData = {
      response: true,
      queryStringParameters: { deviceId },
    };

    const { data } = await API.get("device", "", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const DeviceGetAll = async () => {
  try {
    let reqData = {
      response: true,
    };

    const { data } = await API.get("device", "all", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const DeviceCreate = async ({ body }) => {
  try {
    let reqData = {
      response: true,
      body,
    };

    const { data } = await API.post("device", "", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const DeviceDelete = async ({ deviceId }) => {
  try {
    let reqData = {
      response: true,
      body: { deviceId },
    };

    const { data } = await API.del("device", "", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const GetFirmwareList = async () => {
  try {
    let reqData = {
      response: true,
    };

    const { data } = await API.get("device", "firmware/list", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const DeviceCalibrationStart = async ({ body }) => {
  try {
    let reqData = {
      response: true,
      body,
    };

    const { data } = await API.post("device", "calibration", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const DeviceCalibrationGetStepComplete = async ({
  deviceCalibrationId,
  stepId,
  verifyThermometerRoomTemp,
}) => {
  try {
    let reqData = {
      response: true,
      queryStringParameters: {
        deviceCalibrationId,
        stepId,
        verifyThermometerRoomTemp,
      },
    };

    const { data } = await API.get(
      "device",
      "calibration/step-complete",
      reqData
    );
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const DeviceCalibrationUpdate = async ({ deviceCalibrationId, action }) => {
  try {
    let reqData = {
      response: true,
      body: { deviceCalibrationId, action },
    };

    const { data } = await API.put("device", "calibration", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const DeviceConnectivityTestStart = async ({ deviceId }) => {
  try {
    let reqData = {
      response: true,
      body: { deviceId },
    };

    const { data } = await API.post("device", "connectivity-test", reqData);
    return data;
  } catch (err) {
    throw err?.response?.data?.error;
  }
};

const Methods = {
  DeviceGet,
  DeviceGetAll,
  DeviceCreate,
  DeviceDelete,
  DeviceCalibrationStart,
  DeviceCalibrationGetStepComplete,
  GetFirmwareList,
  DeviceCalibrationUpdate,
  DeviceConnectivityTestStart,
};

export default Methods;
